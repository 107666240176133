import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: "/question",
    name: "Question",
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionView.vue'),
  },
  {
    path: "/end/hotel",
    name: "Hotel",
    component: () => import(/* webpackChunkName: "about" */ '../views/HotelView.vue'),
  },
  {
    path: "/end/jobseeker",
    name: "JobSeeker",
    component: () => import(/* webpackChunkName: "about" */ '../views/JobSeekerView.vue'),
  },
  {
    path: "/end/private",
    name: "Private",
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivateView.vue'),
  },
  {
    path: "/end/fulltime",
    name: "Fulltime",
    component: () => import(/* webpackChunkName: "about" */ '../views/Fulltime.vue'),
  },
  {
    path: "/end/mixed",
    name: "Mixed",
    component: () => import(/* webpackChunkName: "about" */ '../views/MixedView.vue'),
  },
  {
    path: "/end/student",
    name: "Student",
    component: () => import(/* webpackChunkName: "about" */ '../views/StudentView.vue'),
  },
  {
    path: "/end/no_answer",
    name: "NoAnswer",
    component: () => import(/* webpackChunkName: "about" */ '../views/NoAnswer.vue'),
  },
  {
    path: "/end/less3months-low",
    name: "lessless",
    component: () => import(/* webpackChunkName: "about" */ '../views/Less3MonthsLess.vue'),
  },
  {
    path: "/end/less3months-high",
    name: "lesshigh",
    component: () => import(/* webpackChunkName: "about" */ '../views/Less3MonthsHigh.vue'),
  },
  {
    path: "/end/more3months-low",
    name: "moreless",
    component: () => import(/* webpackChunkName: "about" */ '../views/More3MonthsLess'),
  },
  {
    path: "/end/more3months-high",
    name: "morehigh",
    component: () => import(/* webpackChunkName: "about" */ '../views/More3MonthsHigh'),
  },
  {
    path: "/end/compet-international",
    name: "internatio",
    component: () => import(/* webpackChunkName: "about" */ '../views/CompetInternatio'),
  },
  {
    path: "/end/mineur",
    name: "mineur",
    component: () => import(/* webpackChunkName: "about" */ '../views/Mineur'),
  },
  {
    path: "/end/conj-eu",
    name: "conjeu",
    component: () => import(/* webpackChunkName: "about" */ '../views/ConjEU'),
  },
  {
    path: "/end/conj-noneu",
    name: "conjnoneu",
    component: () => import(/* webpackChunkName: "about" */ '../views/ConjNonEU'),
  },
  {
    path: "/end/mineur-noneu",
    name: "mineurnoneu",
    component: () => import(/* webpackChunkName: "about" */ '../views/MineurNonEU'),
  },
  {
    path: "/end/prep-year",
    name: "prepyear",
    component: () => import(/* webpackChunkName: "about" */ '../views/PrepYear'),
  },
  {
    path: "/end/sup-school",
    name: "supschool",
    component: () => import(/* webpackChunkName: "about" */ '../views/SupSchool'),
  },
  {
    path: "/end/prom-social",
    name: "promsocial",
    component: () => import(/* webpackChunkName: "about" */ '../views/PromSocial'),
  },
  {
    path: "/end/sufficient_resources",
    name: "suffresources",
    component: () => import(/* webpackChunkName: "about" */ '../views/SuffResources'),
  },
  {
    path: "/end/limited",
    name: "limited",
    component: () => import(/* webpackChunkName: "about" */ '../views/LimitedView'),
  },
  {
    path: "/end/unlimited",
    name: "unlimited",
    component: () => import(/* webpackChunkName: "about" */ '../views/UnlimitedView'),
  },
  {
    path: "/end/european",
    name: "european",
    component: () => import(/* webpackChunkName: "about" */ '../views/European'),
  },
  {
    path: "/end/belgian",
    name: "belgian",
    component: () => import(/* webpackChunkName: "about" */ '../views/BelgianView'),
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: () => import('../views/DisclaimerView'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0) // scroll to the top of the page
  next() // proceed with the navigation
})

export default router
