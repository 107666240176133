<template>
  <div class="relative flex flex-col items-center justify-end h-screen overflow-hidden">
    <div class="absolute inset-0 w-full h-full">
      <img class="h-full w-full object-cover" src="@/assets/background.jpeg" alt="background-image" />
      <div class="absolute w-full h-full sm:h-4/5 bottom-0 bg-gradient-to-t from-gray-900 to-transparent sm:opacity-90"></div>
    </div>

    <div class="flex flex-col gap-6 text-white z-10 container p-8 bg-nav bg-opacity-80 md:bg-transparent sm:rounded-t-lg">
      <h1 class="text-2xl">Key to sign - Your guide to sign hockey players in Belgium</h1>
      <p class="text-justify">
        The legal complexity of signing foreign field hockey players in Belgium can pose challenges for teams and players. Together with Arnaud Vangansbeek, we have developed a tool to assist teams in navigating these regulations and requirements. This tool provides a comprehensive understanding of the relevant laws and regulations, simplifying the process and ensuring compliance with all legal requirements. The goal is to make the process of signing foreign field hockey players in Belgium as streamlined and straightforward as possible.
      </p>
      <div class="flex flex-wrap gap-6 items-center justify-between">
        <p class="text-justify">
          If you have any questions about the tool, please don't hesitate to reach us. We're here to help!
        </p>
        <router-link to="/question" class="inline-flex items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-base text-white button-shadow bg-gradient-to-t from-blue-400 to-nav-btn">
          Take the test!
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";

export default {
  name: "Home",
  components: { SideBar },
};
</script>

<style scoped>
</style>
