<template>
  <Popover class="relative z-30 bg-nav custom-shadow">
    <div class="mx-auto max-w-7xl px-4 sm:px-6">
      <div class="flex items-center justify-between py-6 md:justify-start md:space-x-10">
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link class="flex items-center" :to="{name: 'home'}">
            <img alt="Key to sign logo" class="h-10" src="@/assets/logo.png">
          </router-link>
        </div>
        <div class="-my-2 -mr-2 md:hidden">
          <PopoverButton class="inline-flex items-center justify-center rounded-md bg-nav p-2 text-gray-400 outline-none">
            <span class="sr-only">Open menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden space-x-10 md:flex">

          <router-link to="/" class="text-base font-medium text-white hover:text-light-primary">Home</router-link>
          <router-link to="/about/" class="text-base font-medium text-white hover:text-light-primary">About Us</router-link>

        </PopoverGroup>
        <div class="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
          <router-link
              :to="'/question'"
              class="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-base text-white button-shadow bg-gradient-to-t from-blue-400 to-nav-btn">
            Take the test!
          </router-link>
        </div>
      </div>
    </div>

    <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <PopoverPanel focus class="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
        <div class="rounded-lg bg-background text-nav shadow-lg ring-1 ring-black ring-opacity-5 custom-shadow">
          <div class="px-5 pt-5 pb-6">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img alt="Key to sign logo" class="h-10" src="@/assets/logo.png">
              </div>
              <div class="-mr-2">
                <PopoverButton class="inline-flex items-center justify-center rounded-md text-nav p-2 outline-none">
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6 " aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="flex flex-col items-center gap-y-8">
                <a v-for="item in solutions" :key="item.name" :href="item.href" class="-m-3 flex items-center rounded-md p-3 w-full text-nav">
                  <component :is="item.icon" class="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                  <span class="flex items-center h-full ml-3 text-base font-medium ">{{ item.name }}</span>
                </a>
              </nav>
            </div>
          </div>
          <div class="space-y-6 py-6 px-5">
            <div>
              <a href="/question" class="flex w-full inline-flex items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-base text-white button-shadow bg-gradient-to-t from-blue-400 to-nav-btn">Take the test!</a>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  HomeIcon,
  XMarkIcon,
    ChatBubbleOvalLeftEllipsisIcon
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const solutions = [
  {
    name: 'Home',
    description: 'Home page.',
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'About us',
    description: 'Discover who we are',
    href: '/about',
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
]
</script>
