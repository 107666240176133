<template>
  <div class="flex flex-col justify-between items-center w-1/4 h-full p-4">
    <div class="text-2xl text-white">Legal Perform</div>
    <div class="text-gray-500 ">
      <div>Menu Item 1</div>
      <div>Menu Item 2</div>
      <div>Menu Item 3</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar"
}
</script>

<style scoped>

</style>
