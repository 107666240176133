<template>
  <div class="flex flex-wrap justify-between bg-nav px-8 py-1 text-sm text-background">
    <p>Copyright © Maxime Denis 2023</p>
    <router-link :to="{name: 'disclaimer'}" class="underline">Disclaimer</router-link>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>